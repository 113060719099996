import React, { useEffect, useState } from 'react';
import { Button, List, ListItem, ListItemText, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const UsersComponent = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', password: '', email: '' });
  const [editUser, setEditUser] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  // Get the backend URL from .env
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Load users from the backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${backendUrl}/users`);
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          throw new Error('Error fetching users from the backend');
        }
      } catch (err) {
        console.error(err);
        setError('Unable to load users.');
      }
    };

    fetchUsers();
  }, [backendUrl]);

  // Delete a user from the backend
  const deleteUser = (username) => {
    fetch(`${backendUrl}/users/${username}`, {
      method: 'DELETE'
    })
      .then((res) => res.json())
      .then(() => {
        setUsers((prevUsers) => prevUsers.filter((user) => user.username !== username));
      })
      .catch((error) => console.error('Error deleting user:', error));
  };

  // Create a new user in the backend
  const createUser = () => {
    fetch(`${backendUrl}/users`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newUser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
        } else {
          setUsers((prevUsers) => [...prevUsers, newUser]);
          setIsDialogOpen(false);
          setNewUser({ username: '', password: '', email: '' });
        }
      })
      .catch((error) => console.error('Error creating user:', error));
  };

  // Open the modal to edit a user
  const handleEditUser = (user) => {
    setEditUser(user);
    setIsEditDialogOpen(true);
  };

  // Update a user in the backend
  const updateUser = () => {
    fetch(`${backendUrl}/users/${editUser.username}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editUser)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
        } else {
          setIsEditDialogOpen(false);
          setEditUser(null);
        }
      })
      .catch((error) => console.error('Error updating user:', error));
  };

  return (
    <div>
      <h2>User Management</h2>

      {/* Show error if unable to load users */}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <List>
        {users.map((user) => (
          <ListItem key={user.username}>
            <ListItemText primary={user.username} secondary={`Email: ${user.email}`} />
            <FaEdit
              style={{ cursor: 'pointer', marginRight: '10px' }}
              onClick={() => handleEditUser(user)}
            />
            <FaTrashAlt
              style={{ cursor: 'pointer', color: 'red' }}
              onClick={() => deleteUser(user.username)}
            />
          </ListItem>
        ))}
      </List>

      <Button variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
        Create User
      </Button>

      {/* Modal to create a new user */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <TextField
            label="Username"
            fullWidth
            margin="dense"
            value={newUser.username}
            onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="dense"
            value={newUser.password}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
          />
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="dense"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={createUser} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal to edit an existing user */}
      {editUser && (
        <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent>
            <TextField
              label="Password"
              type="password"
              fullWidth
              margin="dense"
              value={editUser.password || ''}
              onChange={(e) => setEditUser({ ...editUser, password: e.target.value })}
            />
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="dense"
              value={editUser.email || ''}
              onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsEditDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={updateUser} color="primary">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default UsersComponent;
