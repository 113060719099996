import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import FileStructureRenderer from './FileStructureRenderer'; 
import Uploader from './Uploader'; // Importa tu componente Uploader
import { Button, List, ListItem, ListItemText, Typography } from '@mui/material';

const WorkOnDestinationPage = () => {
  const { authenticatedUser, selectedDestination, setSelectedDestination } = useContext(AppContext);
  const navigate = useNavigate();
  const [destinations, setDestinations] = useState([]);
  const [fileStructure, setFileStructure] = useState(null);
  const [error, setError] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (!authenticatedUser) {
      navigate('/');  
    } else {
      // Cargar las destinations desde el backend
      fetch(`${backendUrl}/destinations`)
        .then((res) => res.json())
        .then((data) => setDestinations(data))
        .catch((err) => setError('Error al cargar las destinations.'));
    }
  }, [authenticatedUser, navigate, backendUrl]);

  const handleDestinationSelect = (destination) => {
    setSelectedDestination(destination);
    fetch(`${backendUrl}/${destination.name}/files`)
      .then((res) => res.json())
      .then((data) => setFileStructure(data))
      .catch((err) => setError('Error al cargar los archivos de la destination.'));
  };

  const handleDownload = (docId, folderName) => {
    const downloadUrl = `${backendUrl}/${selectedDestination.name}/download/${folderName}/${docId}`;
    window.open(downloadUrl, '_blank');
  };

  const handleUploadSuccess = () => {
    if (selectedDestination) {
      fetch(`${backendUrl}/${selectedDestination.name}/files`)
        .then((res) => res.json())
        .then((data) => setFileStructure(data))
        .catch((err) => setError('Error al actualizar los archivos.'));
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Work on Destination
      </Typography>

      <Typography variant="h6" gutterBottom>
        Select a destination to work on:
      </Typography>

      {error && (
        <Typography variant="body2" color="error" gutterBottom>
          {error}
        </Typography>
      )}

      <List>
        {destinations.map((destination) => (
          <ListItem key={destination.name}>
            <ListItemText primary={destination.name} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDestinationSelect(destination)}
            >
              Select
            </Button>
          </ListItem>
        ))}
      </List>

      {selectedDestination && fileStructure && (
        <div>
          <Typography variant="h6" gutterBottom>
            Working on: {selectedDestination.name}
          </Typography>
          <FileStructureRenderer structure={fileStructure} handleDownload={handleDownload} />
          
          {/* Añadir el componente Uploader */}
          <Uploader 
            baseFolder={selectedDestination.name} 
            onUpload={handleUploadSuccess} 
            showSubFolder={true} 
            primary="#000" 
            secondary="#fff"
            selectedDestination={selectedDestination.name} // Pasar el destino seleccionado al Uploader
          />
        </div>
      )}
    </div>
  );
};

export default WorkOnDestinationPage;
