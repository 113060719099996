import React, { useState } from 'react';
import { TextField, Button, Box, IconButton } from '@mui/material';
import axios from 'axios';

const Uploader = ({ 
    baseFolder, 
    onUpload, 
    showSubFolder, 
    primary, // Color primario, pasado por props
    secondary, // Color secundario, pasado por props
    icon: IconComponent,
    selectedDestination  // Pasamos el destination seleccionado
}) => {
    const [docId, setDocId] = useState('');
    const [subFolder, setSubFolder] = useState('');
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
    const apiUrl = process.env.REACT_APP_BACKEND_URL;

    const handleUpload = async () => {
        if (!file || !docId) return;

        const folderName = showSubFolder ? `${baseFolder}/${subFolder}` : baseFolder;
        const formData = new FormData();
        formData.append('document', file);
        formData.append('doc_id', docId);
        formData.append('folder_name', folderName);

        try {
            // Se asegura que la URL de upload incluye el selectedDestination
            const uploadUrl = `${apiUrl}/${selectedDestination}/upload`;
            await axios.post(uploadUrl, formData);
            alert('Document uploaded successfully!');
            setDocId('');
            setSubFolder('');
            setFile(null);
            onUpload(); 
        } catch (error) {
            console.error('Error uploading document:', error);
        }
    };

    return (
        <Box 
            sx={{ 
                width: 400, 
                padding: 2, 
                position: 'relative',
                borderRadius: '8px',
                overflow: 'hidden',
                color: primary, 
                backgroundColor: secondary, 
                opacity: 0.7,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            {IconComponent && (
                <IconButton sx={{ fontSize: '64px' }}>
                    <IconComponent style={{ color: primary, fontSize: 'inherit' }} />
                </IconButton>
            )}
            <TextField
                label="Document ID"
                value={docId}
                onChange={(e) => setDocId(e.target.value)}
                fullWidth
                margin="normal"
                size="small"
                InputLabelProps={{ style: { color: primary } }} 
                InputProps={{
                    style: { color: primary },
                }}
            />
            {showSubFolder && (
                <TextField
                    label={`Folder Name (within ${baseFolder})`}
                    value={subFolder}
                    onChange={(e) => setSubFolder(e.target.value)}
                    fullWidth
                    margin="normal"
                    size="small"
                    InputLabelProps={{ style: { color: primary } }} 
                    InputProps={{
                        style: { color: primary },
                    }}
                />
            )}
            <input type="file" onChange={handleFileChange} style={{ margin: '10px 0' }} />
            <Button
                variant="contained"
                onClick={handleUpload}
                disabled={!file || !docId}
                style={{ 
                    marginTop: '10px', 
                    size: "small", 
                    backgroundColor: secondary,
                    color: primary, 
                    border: `1px solid ${primary}` 
                }}
            >
                Upload
            </Button>
        </Box>
    );
};

export default Uploader;
