// src/components/SAPConnections.js
import React from 'react';
import { Typography } from '@mui/material';

const SAPConnections = () => {
  return (
    <div>
      <Typography variant="h5">SAP Connections</Typography>
      <Typography variant="body1">Coming soon...</Typography>
    </div>
  );
};

export default SAPConnections;
