// src/components/AdminPage.js
import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Box, Drawer, Typography } from '@mui/material';
import UsersComponent from './UsersComponent';
import DestinationsComponent from './DestinationsComponent';
import SAPConnections from './SAPConnections';
import EstablishedLinks from './EstablishedLinks';

const drawerWidth = 240;

const AdminPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Menú lateral */}
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <AppBar position="static">
          <Typography variant="h6" sx={{ padding: 2 }}>
            Admin
          </Typography>
        </AppBar>
        <Tabs
          orientation="vertical"
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Admin Menu"
          sx={{ width: drawerWidth }}
        >
          <Tab label="Users" />
          <Tab label="Destinations" />
          <Tab label="SAP Connections" />
          <Tab label="Established Links" />
        </Tabs>
      </Drawer>

      {/* Contenido de la pestaña seleccionada */}
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {selectedTab === 0 && <UsersComponent />}
        {selectedTab === 1 && <DestinationsComponent />}
        {selectedTab === 2 && <SAPConnections />}
        {selectedTab === 3 && <EstablishedLinks />}
      </Box>
    </Box>
  );
};

export default AdminPage;
