import React, { useEffect, useState } from 'react';
import { Button, List, ListItem, ListItemText, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const DestinationsComponent = () => {
  const [destinations, setDestinations] = useState([]);
  const [newDestination, setNewDestination] = useState({ name: '', source: '', connectionString: '', containerName: '' });
  const [editDestination, setEditDestination] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  // Get the backend URL from .env
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Load destinations from the backend
  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await fetch(`${backendUrl}/destinations`);
        if (response.ok) {
          const data = await response.json();
          setDestinations(data);
        } else {
          throw new Error('Error fetching destinations from the backend');
        }
      } catch (err) {
        console.error(err);
        setError('Unable to load destinations.');
      }
    };

    fetchDestinations();
  }, [backendUrl]);

  // Delete a destination from the backend
  const deleteDestination = (destinationName) => {
    fetch(`${backendUrl}/destinations/${destinationName}`, {
      method: 'DELETE'
    })
      .then((res) => res.json())
      .then(() => {
        setDestinations((prevDestinations) => prevDestinations.filter((destination) => destination.name !== destinationName));
      })
      .catch((error) => console.error('Error deleting destination:', error));
  };

  // Create a new destination in the backend
  const createDestination = () => {
    fetch(`${backendUrl}/destinations`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newDestination)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
        } else {
          setDestinations((prevDestinations) => [...prevDestinations, { ...newDestination }]);
          setIsDialogOpen(false);
          setNewDestination({ name: '', source: '', connectionString: '', containerName: '' });
        }
      })
      .catch((error) => console.error('Error creating destination:', error));
  };

  // Open the modal to edit a destination
  const handleEditDestination = (destinationName) => {
    const destination = destinations.find(dest => dest.name === destinationName);
    if (destination) {
      setEditDestination({ ...destination });
      setIsEditDialogOpen(true);
    }
  };

  // Update a destination in the backend
  const updateDestination = () => {
    fetch(`${backendUrl}/destinations/${editDestination.name}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editDestination)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
        } else {
          setIsEditDialogOpen(false);
          setEditDestination(null);
          // Update the destinations list
          setDestinations((prevDestinations) =>
            prevDestinations.map((destination) =>
              destination.name === editDestination.name ? editDestination : destination
            )
          );
        }
      })
      .catch((error) => console.error('Error updating destination:', error));
  };

  return (
    <div>
      <h2>Destination Management</h2>

      {/* Show error if unable to load destinations */}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <List>
        {destinations.map((destination) => (
          <ListItem key={destination.name}>
            <ListItemText
              primary={destination.name}
              secondary={`Source: ${destination.source}, Container: ${destination.containerName}`}
            />
            <FaEdit
              style={{ cursor: 'pointer', marginRight: '10px' }}
              onClick={() => handleEditDestination(destination.name)}
            />
            <FaTrashAlt
              style={{ cursor: 'pointer', color: 'red' }}
              onClick={() => deleteDestination(destination.name)}
            />
          </ListItem>
        ))}
      </List>

      <Button variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
        Create Destination
      </Button>

      {/* Modal to create a new destination */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Create New Destination</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="dense"
            value={newDestination.name}
            onChange={(e) => setNewDestination({ ...newDestination, name: e.target.value })}
          />
          <TextField
            label="Source"
            fullWidth
            margin="dense"
            value={newDestination.source}
            onChange={(e) => setNewDestination({ ...newDestination, source: e.target.value })}
          />
          <TextField
            label="Connection String"
            fullWidth
            margin="dense"
            value={newDestination.connectionString}
            onChange={(e) => setNewDestination({ ...newDestination, connectionString: e.target.value })}
          />
          <TextField
            label="Container Name"
            fullWidth
            margin="dense"
            value={newDestination.containerName}
            onChange={(e) => setNewDestination({ ...newDestination, containerName: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={createDestination} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal to edit an existing destination */}
      {editDestination && (
        <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
          <DialogTitle>Edit Destination</DialogTitle>
          <DialogContent>
            <TextField
              label="Source"
              fullWidth
              margin="dense"
              value={editDestination.source || ''}
              onChange={(e) => setEditDestination({ ...editDestination, source: e.target.value })}
            />
            <TextField
              label="Connection String"
              fullWidth
              margin="dense"
              value={editDestination.connectionString || ''}
              onChange={(e) => setEditDestination({ ...editDestination, connectionString: e.target.value })}
            />
            <TextField
              label="Container Name"
              fullWidth
              margin="dense"
              value={editDestination.containerName || ''}
              onChange={(e) => setEditDestination({ ...editDestination, containerName: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsEditDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={updateDestination} color="primary">
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default DestinationsComponent;
