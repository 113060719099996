// src/components/FileStructureRenderer.js
import React from 'react';
import { List, ListItem, ListItemText, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FaDownload, FaFolder, FaFile } from 'react-icons/fa';
import { MdExpandMore } from 'react-icons/md';  // Usando react-icons para expandir

const FileStructureRenderer = ({ structure, handleDownload }) => {

    const renderFileStructure = (structure, parentFolder = '') => {
        if (!structure || typeof structure !== 'object') {
            return null;  // Handle the case where the structure is not valid
        }

        return Object.entries(structure).map(([folder, content]) => {
            if (folder === "files" && Array.isArray(content)) {
                // Render files
                return content.map((file) => (
                    <ListItem key={file} style={{ paddingLeft: '20px', backgroundColor: '#f0f7ff' }}>
                        <FaFile style={{ marginRight: '10px' }} />
                        <ListItemText primary={file} />
                        <IconButton edge="end" onClick={() => handleDownload(file.split('.')[0], parentFolder)}>
                            <FaDownload />
                        </IconButton>
                    </ListItem>
                ));
            } else if (typeof content === 'object' && content !== null) {
                // Render folders
                const currentFolderName = folder.split('/').pop();  // Extraer solo el nombre de la carpeta actual
                return (
                    <Accordion key={folder} style={{ backgroundColor: '#fffde7' }}>
                        <AccordionSummary expandIcon={<MdExpandMore />}>
                            <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center' }}>
                                <FaFolder style={{ marginRight: '10px' }} />
                                {currentFolderName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List style={{ paddingLeft: '20px' }}>
                                {renderFileStructure(content, parentFolder ? `${parentFolder}/${currentFolderName}` : currentFolderName)}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                );
            } else {
                // Handle unexpected content type
                return null;
            }
        });
    };

    return (
        <List>
            {renderFileStructure(structure)}
        </List>
    );
};

export default FileStructureRenderer;
