// src/context/AppContext.js
import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);

  return (
    <AppContext.Provider value={{ authenticatedUser, setAuthenticatedUser, selectedDestination, setSelectedDestination }}>
      {children}
    </AppContext.Provider>
  );
};
