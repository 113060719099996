// src/components/EstablishedLinks.js
import React from 'react';
import { Typography } from '@mui/material';

const EstablishedLinks = () => {
  return (
    <div>
      <Typography variant="h5">Established Links</Typography>
      <Typography variant="body1">Coming soon...</Typography>
    </div>
  );
};

export default EstablishedLinks;
