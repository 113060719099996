// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminPage from './components/AdminPage';
import WorkOnDestinationPage from './components/WorkOnDestinationPage';
import LoginPage from './components/LoginPage';  // Agregamos el componente de login
import { AppProvider } from './context/AppContext';

const App = () => {
  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />  {/* Ruta de login */}
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/work-on-destination" element={<WorkOnDestinationPage />} />
        </Routes>
      </Router>
    </AppProvider>
  );
};

export default App;
